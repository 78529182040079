import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Intro from "../components/page-intro"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { motion } from 'framer-motion'
import Swiper from "swiper"
import 'swiper/swiper-bundle.min.css'
import straightShape from '../images/straight-long-shape.svg'


const duration = 0.25

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { x: 0, y: 20, opacity: 0 },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
  },
}


const Unidays = () => {
    const data = useStaticQuery(graphql`
      query {
      allDirectory {
        edges {
          node {
            id
          }
        }
      }
      poster1: file(relativePath: {eq: "unidays-poster-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      poster2: file(relativePath: {eq: "unidays-poster-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      poster3: file(relativePath: {eq: "unidays-poster-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      poster4: file(relativePath: {eq: "unidays-poster-4.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      poster5: file(relativePath: {eq: "unidays-poster-5.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unidaysCampaign: file(relativePath: {eq: "unidays-campaign-hero.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unidaysStory1: file(relativePath: {eq: "unidays-story-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unidaysStory2: file(relativePath: {eq: "unidays-story-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unidaysStory3: file(relativePath: {eq: "unidays-story-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unidaysStory4: file(relativePath: {eq: "unidays-story-4.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unilab1: file(relativePath: {eq: "unilab-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unilab2: file(relativePath: {eq: "unilab-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unilab3: file(relativePath: {eq: "unilab-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unilab4: file(relativePath: {eq: "unilab-4.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      swoty: file(relativePath: {eq: "unidays-swoty.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      americanAparel: file(relativePath: {eq: "unidays-aa.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unidaysSocial: file(relativePath: {eq: "social-unidays.jpg"}) {
        childImageSharp {
          fixed(width: 1200, height: 630, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
`)

  const swiper = useRef(null)

  useEffect(()=>{
    setTimeout(function(){
      swiper.current = new Swiper('.swiper-container',{
        slidesPerView: 1,
        spaceBetween: 50,
        freeMode: true,
      })
     },1000)
    
  },[])

  return (
    <>
      <SEO 
        title="UNiDAYS Creative Designer" 
        description="Creative designer for Gen-Z oriented student savings platform." 
        image={data.unidaysSocial.childImageSharp.fixed}
      />
      <Helmet
        htmlAttributes={{
          class: 'unidays bg-unidaysGrad text-black',
        }}
      />

   
      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
        className="intro"
      >

        <Intro 
          borderColor="border-black border-opacity-20"
          introHeader={["UNiDAYS —"]}
          introSubHeader={["Creative designer"]}
          introSummary="As part of the creative team at UNiDAYS – a leading student savings platform – I worked on a variety of projects, from internal brand development and campaigns, to creative campaigns with partner brands such as American Apparel.">
        </Intro>
      
        <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
          <div className="border-b"></div>
          <div className="border-b border-l  p-6 md:p-10 overflow-hidden ">

              <motion.div 
                variants={container}
                transition="easeInOut"
                className="intro-bottom flex lg:flex-wrap flex-row md:space-x-1 lg:space-x-1 items-center justify-center">
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="flex-grow">
                    <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="167.141" height="167.142" viewBox="0 0 167.141 167.142"><path d="M167.141,83.572,83.57,0,0,83.572l83.57,83.57ZM83.57,63l20.572,20.572L83.57,104.143,63,83.572Z" transform="translate(0 0)" fill="#000000"/></svg>
                </motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="flex-grow ">
                    <svg className="mx-auto transform rotate-90" xmlns="http://www.w3.org/2000/svg" width="199.216" height="57.18" viewBox="0 0 199.216 57.18"><path d="M100.278,56.956a294.444,294.444,0,0,0,98.938-21.088L184.675,0a255.647,255.647,0,0,1-85.9,18.3A255.733,255.733,0,0,1,11.712,6.684L0,43.567A294.56,294.56,0,0,0,100.278,56.956Z" fill="#000000"/></svg>
                </motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="flex-grow">
                    <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="103.447" height="103.447" viewBox="0 0 103.447 103.447"><path d="M0,73.294,73.295,0l30.153,30.153L30.152,103.447Z" fill="#000000"/></svg>
                </motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="flex-grow ">
                      <img backgroundColor="#F1EBE8" alt="Brand shape" className="mx-auto" src={straightShape} />
                </motion.div>
                <motion.div 
                  variants={item}
                  transition="easeInOut"
                  className="flex-grow">
                   <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="94.413" height="135.07" viewBox="0 0 94.413 135.07"><path d="M0,112.913A474.688,474.688,0,0,0,57.841,0L94.413,12.934A513.49,513.49,0,0,1,31.838,135.07Z" transform="translate(0)" fill="#000000"/></svg>
                </motion.div>
              </motion.div>
            
          </div>
          <div className="border-b border-l"></div>
        </div>
      </motion.section>

      <section className="content">

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl bg-white">
        <div className="border-b"></div>
        <div className="border-b border-l  p-6 md:p-10 ">

          <div className="grid grid-cols-12 md:grid-rows-12 gap-4 md:gap-6 lg:gap-8 xl:pt-20 xl:px-20">
            
            <div className="col-start-1 col-span-12 md:col-span-6 md:row-span-4">
              <Img backgroundColor="#50BA72" alt="UNiDAYS Values Poster Design 1" fluid={data.poster1.childImageSharp.fluid}  />
            </div>

            <div className="col-start-1 col-span-6 md:col-start-7 md:col-span-3 md:mt-20 md:-row-span-2 md:row-start-2 md:row-end-4">
              <Img backgroundColor="#DDE24A" alt="UNiDAYS Values Poster Design 2" fluid={data.poster2.childImageSharp.fluid}  />
            </div>

            <div className="col-start-7 col-span-6 md:col-start-10 md:col-span-3 md:mt-20 md:-row-span-2 md:row-start-2 md:row-end-4">
              <Img backgroundColor="#00BADB" alt="UNiDAYS Values Poster Design 3" fluid={data.poster3.childImageSharp.fluid}  />
            </div>

             <div className="col-start-1 col-span-6 md:col-start-7 md:col-span-3  md:-row-span-2 md:row-start-4">
              <Img backgroundColor="#000000" alt="UNiDAYS Values Poster Design 4" fluid={data.poster4.childImageSharp.fluid}  />
            </div>

            <div className="col-start-7 col-span-6 md:col-start-10 md:col-span-3 md:-row-span-2 md:row-start-4">
              <Img backgroundColor="#50BA72" alt="UNiDAYS Values Poster Design 5" fluid={data.poster5.childImageSharp.fluid}  />
            </div>

            <div className="col-start-1 col-span-12 py-8 md:col-start-1 md:col-span-5 md:-mt-20 md:row-span-2 md:row-start-5 lg:col-start-2 lg:col-span-4">
              <h2>Values posters</h2>
              <p>I had some fun with the UNiDAYS brand to create a set of posters to highlight the business's values and culture.</p>
            </div>

          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>



      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl bg-white">
        <div className="border-b"></div>
        <div className="border-b border-l  p-6 md:p-10">

          <Img backgroundColor="#DDE24A" alt="UNiDAYS Refer a friend - Campaign visual" fluid={data.unidaysCampaign.childImageSharp.fluid} className="image-scale-in xl:mt-20 xl:mx-20" />

          <div className="md:grid grid-cols-12 gap-10 pt-10 xl:px-48 py-20 xl:py-32">
            
            <div className="col-start-1 col-span-4">
              <h2>Refer a friend campaign</h2>
            </div>
          
            <div className="col-start-7 col-span-6">
              <p className="mb-6">I lead the creative work on UNiDAYS' refer a friend campaign from concept through to execution, working with the wider team of copywriters and creatives to bring the campaign to life.</p>
              <p>The campaign needed to represent the Gen-Z target audience whilst remaining on-brand and clearly communicating the mechanics of the refer a friend scheme.</p>
            </div>

          </div>

          <div className="grid grid-cols-12 gap-4 py-10 md:gap-10 md:px-20 md:pt-20">
         
            <div className="col-start-1 col-span-6 md:col-start-1 md:col-span-3">
              <Img backgroundColor="#50BA72" alt="UNiDAYS Refer a friend - Insta story" fluid={data.unidaysStory1.childImageSharp.fluid}  />
            </div>

            <div className="col-start-7 col-span-6 md:col-start-4 md:col-span-3">
              <Img backgroundColor="#50BA72" alt="UNiDAYS Refer a friend - Insta story" fluid={data.unidaysStory2.childImageSharp.fluid}  />
            </div>

            <div className="col-start-1 col-span-6 md:col-start-7 md:col-span-3">
              <Img backgroundColor="#50BA72" alt="UNiDAYS Refer a friend - Insta story" fluid={data.unidaysStory3.childImageSharp.fluid}  />
            </div>

            <div className="col-start-7 col-span-6 md:col-start-10 md:col-span-3">
              <Img backgroundColor="#50BA72" alt="UNiDAYS Refer a friend - Insta story" fluid={data.unidaysStory4.childImageSharp.fluid}  />
            </div>

          </div>

          <div className="md:grid grid-cols-12 gap-10 mb-10 md:mb-20  xl:px-20 xl:py-20  ">
          
            <div className="col-start-7 col-span-6  xl:pr-24 ">
              <p>Execution of the campaign needed to work across all of UNiDAYS' channels and touch-points, from website and email to scoial and advertising.</p>
            </div>

          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>


      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl bg-white">
        <div className="border-b"></div>
        <div className="border-b border-l p-6 md:p-20 bg-gray-100">

          <div className="grid grid-cols-12 gap-4 md:gap-10">

             <div className="col-start-1 col-span-12  md:col-span-6">
              <Img backgroundColor="#00BADB" alt="UNiDAYS - UNiLAB Brand Concept" fluid={data.unilab1.childImageSharp.fluid}  />
            </div>

            <div className="col-start-1 col-span-12 md:col-start-7 md:col-span-6">
              <Img backgroundColor="#DDE24A" alt="UNiDAYS - UNiLAB Brand Concept" fluid={data.unilab2.childImageSharp.fluid}  />
            </div>

            <div className="col-start-1 col-span-6">
              <Img backgroundColor="#50BA72" alt="UNiDAYS - UNiLAB Brand Concept" fluid={data.unilab3.childImageSharp.fluid}  />
            </div>

            <div className="col-start-7 col-span-6">
              <Img backgroundColor="#00BADB" alt="UNiDAYS - UNiLAB Brand Concept" fluid={data.unilab4.childImageSharp.fluid}  />
            </div>
            
          </div>

          <div className="md:grid grid-cols-12 gap-10 pt-10 xl:px-48 py-20 xl:py-32">         
            
              <div className="col-start-1 col-span-4">
                <h2>UNiLAB Brand</h2>
              </div>
            
              <div className="col-start-7 col-span-6">
                <p>Wtih the launch of an internal innovation hub, UNiDAYS required a unique brand identity to represent the creative, fun, technology-focused team of innovators.</p>
                <p>Here is some initial concept work aiming to bring together and elevate the orginial UNiDAYS brand whilst keeping a human focus to the execution.</p>
              </div>

            </div>

        </div>
        <div className="border-b border-l"></div>
      </div>




      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl bg-white">
        <div className="border-b"></div>
        <div className="border-b border-l  p-6 md:p-10 bg-unidaysBeige">

          <div className="grid gap-4 grid-cols-12 md:gap-10 md:py-12 xl:px-48 xl:py-32">
            <div className="col-start-1 col-span-12 md:col-span-6">
              <h2>Student Woman of the Year</h2>
            </div>
            <div className="col-start-1 col-span-12 md:col-start-7 md:col-span-6 lg:col-span-5">
              <p className="text-sm md:text-base">To celebrate International Women's Day with their audience, UNiDAYS launched Student Woman of the Year. In 2018 they partnered with MISSGUIDED – here, the two brands come together for the dedicated landing page.</p>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 md:gap-10">

            <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10 py-20">
              <Img backgroundColor="#50BA72" alt="UNiDAYS x Student Woman of the Year - Landing page design" fluid={data.swoty.childImageSharp.fluid}  />
            </div>
            
          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl bg-white">
        <div className="border-b"></div>
        <div className="border-b border-l  p-6 md:p-10 bg-unidaysBeige">

          <div className="grid gap-4 grid-cols-12 md:gap-10 md:py-12 xl:px-48 xl:py-32">
            <div className="col-start-1 col-span-12 md:col-span-6">
              <h2>UNiDAYS x American Apparel</h2>
            </div>
            <div className="col-start-1 col-span-12 md:col-start-7 md:col-span-6 lg:col-span-5">
              <p className="text-sm md:text-base">UNiDAYS ran their 'Launchpad' campaign with partner brand American Apparel – a platform for providing unique opportunities to their Gen-Z audience. Landing page designs for the campaign were created, brining the two brands together in order to communicate the details of the campaign. </p>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 md:gap-10">
        
            <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10 py-20">
              <Img backgroundColor="#00BADB" alt="UNiDAYS x American Apparel - Landing page design" fluid={data.americanAparel.childImageSharp.fluid}  />
            </div>
            
          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>
      </section>

      
    </>
  )
}



export default Unidays

